import axios from 'axios-config';
import { PitchInsightResponse } from './pitch-insight-response';

export class PitchInisghtService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async get(): Promise<PitchInsightResponse> {
        const { data } = await axios.get(`api/v2/AgencyPitchInsight/Get`, this.axiosConfig);
        return data;
    }

    public static async testInterest(pitchId: number) {
        const { data } = await axios.post(
            `api/PlayerPitch/TestInterest/${pitchId}`,
            {},
            this.axiosConfig,
        );
        return data;
    }
}
