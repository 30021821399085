import axios from 'axios-config';
import { OpportunitiesResponse, RecentPitches, RecentPlayers, MySquadResponse, PitchItem } from './models/transfers-out';
import AuthState from 'store/auth/auth-state';

export default class TransfersOutService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };


    public static async getRecentPitches(): Promise<RecentPitches> {
        const { data } = await axios.get(
            `api/v2/transfer-out/recent-pitches`,
            { ...this.axiosConfig }
        )
        return data;
    }

    public static async getOpportunities(): Promise<OpportunitiesResponse> {
        const { data } = await axios.get(
            `api/v2/transfer-out/opportunities`,
            { ...this.axiosConfig }
        )
        return data;
    }

    public static async opportunityDecline(createdBySquadId: number, playerAdId: number) {
        return await axios.post(
            `api/v2/transfer-out/opportunity-decline`,
            {
                createdBySquadId,
                playerAdId,
            },
            { ...this.axiosConfig }
        )
    }

    public static async getMySquad(): Promise<MySquadResponse> {
        const { data } = await axios.get(
            `api/v2/transfer-out/my-squad`,
            { ...this.axiosConfig }
        )
        return data;
    }

    public static async loadRecommendedPlayersBanner(): Promise<any> {
        const { data } = await axios.get(
            `api/v2/MySquad/ShowBanner`,
            this.axiosConfig
        )
        return data;
    }

    public static async matchMakerFetch(squadId: number, userId: number): Promise<any> {
        const { data } = await axios.get(
            `api/MySquad/GetMySquadRecommendationPerPlayer/${squadId}/${userId}`,
            this.axiosConfig
        )
        return data;
    }

    public static async makePlayerNotAvailable(playerId: number) {
        return await axios.post(
            `api/v2/transfer-out/not-available`,
            {
                playerId,
            },
            { ...this.axiosConfig }
        )
    }

    public static async makePlayerAvailable(playerId: number) {
        return await axios.post(
            `api/v2/transfer-out/not-available`,
            {
                playerId,
            },
            { ...this.axiosConfig }
        )
    }

    public static async getSavedPitches(): Promise<PitchItem[]> {
        const { data } = await axios.get(
            `api/v2/transfer-out/saved-pitches`,
            { ...this.axiosConfig }
        )        
        return data;
    }
}