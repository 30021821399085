import {AppState} from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { Actions as RibbonActions } from './transfers-out-ribbon.controller';
import { Actions as TopOpportunitiesActions } from './transfers-out-top-opportunities.controller';
import { Actions as MySquadActions } from './transfers-out-my-squad-controller';
import { Actions as InsightsActions } from './transfers-out-insights.controller';
import TransfersOutService from 'api/transfers-out/transfers-out.service';
import {  OpportunityItem } from 'api/transfers-out/models/transfers-out';

class TransfersOutCommonState {
    isLoading: boolean;
    opportunities: OpportunityItem[];
    newOpportunitiesCounter: number;
}

const defaultState: TransfersOutCommonState = {
    isLoading: false,
    opportunities: [],
    newOpportunitiesCounter: 0
}

const stateController = new StateController<TransfersOutCommonState>(
    'SQUAD_TRANSFER_OUT/COMMON',
    defaultState
);

class Actions {
    public static dispose() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static init() {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isLoading: true }));
                await dispatch(Actions.getOpportunities());
                await dispatch(RibbonActions.getRibbonData());
                await dispatch(TopOpportunitiesActions.initTopOpportunities());
                await dispatch(MySquadActions.initMySquadData());
                await dispatch(InsightsActions.getInsightsData());
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static getOpportunities() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await TransfersOutService.getOpportunities();
                dispatch(stateController.setState({
                    opportunities: data.opportunities,
                    newOpportunitiesCounter: data.newOpportunitiesCounter
                }));
            } catch (error) {
                console.error(error);
            }
        }
    }
}


class Selectors {
    public static getRoot = (state: AppState) => state.transfersOut.common;
    public static isLoading = (state: AppState) => Selectors.getRoot(state).isLoading;
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersOutCommonState as State,
    Actions as Actions,
    stateController as Controller
};