import { PitchInisghtService } from 'api/agency/pitch-insight/agency-pitch-insight.service';
import { SuperPitchService } from 'api/super-pitch/super-pitch';
import { PlusPitchService } from 'api/plus-pitch/plus-pitch.service';
import TransfersOutInsightsService from 'api/transfers-out/insights/transfers-out-insights.service';
import {
    ActivityType,
    InsightsActivityType,
    InsightsFilterTypesEnum,
} from 'api/transfers-out/models/transfers-out-insights.model';
import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { Selectors as TransferOutPlayerDetailSelector } from './transfer-out-player-detail.controller';
import { v4 } from 'uuid';
import { getAuth } from 'store/auth/authReducer';

class TransfersOutInsightsTabState {
    activities: InsightsActivityType[];
    activitiesState: 'loading' | 'success';
    selectedFilter: InsightsFilterTypesEnum;
}

const defaultState: TransfersOutInsightsTabState = {
    activities: [],
    activitiesState: 'loading',
    selectedFilter: InsightsFilterTypesEnum.All,
};

const stateController = new StateController<TransfersOutInsightsTabState>(
    'SQUAD_TRANSFER_OUT/INSIGHTS_TAB',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static getPlayerInsights() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    activitiesState: 'loading',
                }))
            );

            try {
                const player = TransferOutPlayerDetailSelector.getPlayer(getState());
                const playersActivity = await TransfersOutInsightsService.getPlayerActivity(
                    player.id
                );
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        activities: playersActivity.activity.map((v) => ({
                            ...v,
                            idFE: v4(),
                        })),
                    }))
                );
            } catch (error) {
            } finally {
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        activitiesState: 'success',
                    }))
                );
            }
        };
    }

    public static setPlayerInsightsFilter(filter: InsightsFilterTypesEnum) {
        return (dispatch, _) => {
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    selectedFilter: filter,
                }))
            );
        };
    }

    public static testInterest(activity: InsightsActivityType) {
        return async (_, getState: () => AppState) => {
            const user = getAuth(getState());

            if (activity.regularPitchId) {
                await PitchInisghtService.testInterest(activity.regularPitchId);
            }

            if (activity.plusPitchSquadId) {
                await PlusPitchService.testInterest(activity.plusPitchSquadId);
            }

            if (activity.superPitchSquadId) {
                await SuperPitchService.testInterest(
                    activity.squadId,
                    user.userId,
                    activity.superPitchSquadId,
                    activity.superPitchSquadId
                );
            }
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersOut.insightsTab;
    private static getPlayerActivity = (state: AppState) => Selectors.getRoot(state).activities;
    public static getFilteredPlayerActivity = (state: AppState) => {
        const selectedFilter = Selectors.selectedFilter(state);

        const filterFunction = (act: InsightsActivityType) => {
            switch (selectedFilter) {
                case InsightsFilterTypesEnum.SavedPitches:
                    return act.type === ActivityType.Saved;
                case InsightsFilterTypesEnum.ActionableItems:
                    return act.type === ActivityType.Saved && act.canTestInterest;
                case InsightsFilterTypesEnum.Interest:
                    return act.type === ActivityType.DeclaredInterest;
                case InsightsFilterTypesEnum.All:
                    return true;
            }
        };

        return Selectors.getPlayerActivity(state).filter(filterFunction);
    };
    public static isPlayerActivityLoading = (state: AppState) =>
        Selectors.getRoot(state).activitiesState === 'loading';
    public static isPlayerActivityEmpty = (state: AppState) =>
        Selectors.getPlayerActivity(state).length === 0;
    private static selectedFilter = (state: AppState) => Selectors.getRoot(state).selectedFilter;
    public static isFilterSelected = (state: AppState, filter: InsightsFilterTypesEnum) =>
        Selectors.selectedFilter(state) === filter;
    public static getFilterCount = (state: AppState, filter: InsightsFilterTypesEnum) => {
        const playerActivity = Selectors.getPlayerActivity(state);

        switch (filter) {
            case InsightsFilterTypesEnum.All:
                return playerActivity.length;
            case InsightsFilterTypesEnum.ActionableItems:
                return playerActivity.filter(
                    (activity) => activity.type === ActivityType.Saved && activity.canTestInterest
                ).length;
            case InsightsFilterTypesEnum.Interest:
                return playerActivity.filter(
                    (activity) => activity.type === ActivityType.DeclaredInterest
                ).length;
            case InsightsFilterTypesEnum.SavedPitches:
                return playerActivity.filter((activity) => activity.type === ActivityType.Saved)
                    .length;
            default:
                const val: never = filter;
                return val;
        }
    };
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersOutInsightsTabState as State,
    Actions as Actions,
    stateController as Controller,
};
