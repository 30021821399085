import { FormattedPriceModel } from 'api/core/formatted-price-model';
import { CurrencyModel } from 'api/common/models';

export interface InsightsRecentPitchesResponse {
    recentPlayers: InsightsRecentPitchType[];
}

export interface InsightsRecentPitchType {
    playerId: number;
    playerName: string;
    updatesCount: number;
}

export interface InsightsHighlightsResponse {
    highlights: InsightsHighlightsType[];
}

export interface InsightsHighlightsType {
    playerId: number;
    playerName: string;
    savedCount: number;
    testInterestsCount: number;
    savedPitchItems: HighlightsPitchItemType[];
    declinedPitchItems: HighlightsPitchItemType[];
}

export interface HighlightsPitchItemType {
    plusSuperPitchId: number;
    plusPitchSquadId: number;
    superPitchSquadId: number;
    regularPitchId: number;
    squadId: number;
    squadName: string;
}

export interface InsightsActivityResponse {
    activity: InsightsActivityType[];
}

export interface InsightsActivityType {
    plusSuperPitchId: number;
    plusPitchSquadId: number;
    superPitchSquadId: number;
    regularPitchId: number;
    playerId: number;
    playerName: string;
    dateTime: string;
    type: ActivityType;
    squadId: number;
    squadName: string;
    squadLogo: string;
    canTestInterest: boolean;
    isTestInterestSent: boolean;
    leagueName: string;
    idFE: string;
}

export enum ActivityType {
    Default = 0,
    Saved = 1,
    DeclaredInterest = 2,
    Dismissed = 3,
    Removed = 4,
}

export interface InsightsCountersType {
    playerId: number;
    playerName: string;
    utilisationScoreData: UtilisationScoreType;
    totalOpportunities: number;
    pitchesSent: number;
    newPitchOpportunities: number;
    requestFinalAnswer: number;
    plusPitches: number;
    savedPitches: number;
    savedPitchesNew: number;
    interests: number;
    interestsNew: number;
}

export interface UtilisationScoreType {
    availableScore: number;
    totalPitchesScore: number;
    pitchQualityScore: number;
    finalAnswerScore: number;
    plusPitchScore: number;
    score: number;
    nRelPitches: number;
}

export enum SentPitchesEnum {
    Default = 0,
    SentPitches = 1,
    RequestFinalAnswer = 2,
    PlusPitches = 3,
    SavedPitches = 4,
    DeclaredInterest = 5,
}

export interface InsightsSentPitchesResponse {
    sentPitches: InsightsSentPitchesType[];
}

export interface InsightsSentPitchesType {
    playerId: number;
    playerName: string;
    plusSuperPitchId: number;
    plusPitchSquadId: number;
    superPitchSquadId: number;
    regularPitchId: number;
    dateTime: string;
    statusType: PitchStatusType;
    league: {
        areaId: number;
        areaName: string;
        id: number;
        logo: string;
        name: string;
    };
    buy: FormattedPriceModel;
    loan: FormattedPriceModel;
    squadId: number;
    squadName: string;
    squadLogo: string;
    canTestInterest: boolean;
}

export enum PitchStatusType {
    Undefined = 0,
    Delivered = 1,
    Opened = 2,
    Saved = 3,
    DeclaredInterest = 4,
    Dismissed = 5,
    Removed = 6,
    ClosedWon = 7,
    ClosedLost = 8,
    ClosedExpired = 9,
}

export enum InsightsFilterTypesEnum {
    All = 'All',
    ActionableItems = 'ActionableItems',
    Interest = 'Interest',
    SavedPitches = 'SavedPitches',
}
