export interface PlayerPositionsResponse {
    positions: SearchPlayerPosition[];
}

export interface SearchPlayerPosition {
    id: SearchPlayerPositionEnum;
    name: string;
    shortName?: string;
}

export enum SearchPlayerPositionEnum {
    Goalkeeper = 1,
    LeftBack = 2,
    RightBack = 3,
    CentreBack_All = 4,
    CentreBack_LeftFooted = 5,
    DefensiveMidfielder = 6,
    CentralMidfielder = 7,
    AttackingMidfielder = 8,
    Winger = 9,
    Forward = 10,
}