import { StateController } from 'utils/action-declaration';
import { AppState } from 'root.reducer';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { Permissions as PermissionsService } from 'api/permission/permission.servise'
import { simulateServerCall } from 'utils/simulate-server-call';

class UpgradeSubscriptionPopupState {
    isOpen: boolean;
    isBusy: boolean;
    step?: number;
    UAPageName?: string;
    pageName?: string;
    playerId?: number | null;
}

const defaultState: UpgradeSubscriptionPopupState = {
    isOpen: false,
    isBusy: false,
    step: null,
    UAPageName: '',
    pageName: '',
    playerId: null,
}

const stateController = new StateController<UpgradeSubscriptionPopupState>(
    "CLUB/UPDGRADE_SUBSCRIPTION_MODAL",
    defaultState
);

class Actions {

    public static closeModal() {
        return async (dispatch, getState: () => AppState) => {
            // const { UAPageName, playerId } = getState().upgradeSubscriptionPopup;
            // let squadId = getState().auth.squadId;
            dispatch(stateController.setState({ isOpen: false }))
            // if (Boolean(isUserActivity && UAPageName)) {
            //     switch (UAPageName) {
            //         case 'Search [Highlight]':
            //             dispatch(Actions.sendUserActivity(UAPageName, 'Closed Availability Restriction'));
            //             break;
            //         case 'Messaging [Restriction]':
            //         case 'Player Ads [Restriction]':
            //         case 'Search Bar [Restriction]':
            //             dispatch(Actions.sendUserActivity(UAPageName, 'Close'));
            //             break;
            //         case 'Player Profile [Restriction]':
            //             dispatch(Actions.sendUserActivity(UAPageName, 'Close', squadId, playerId ));
            //             break;
            //         default:
            //             dispatch(Actions.sendUserActivity(UAPageName, 'Closed'));
            //     }
            // }
        }
    }

    public static openModal(UAPageName?: string, pageName?: string, playerId?: number) {
        return async (dispatch) => {
            if (UAPageName) {
                dispatch(stateController.setState({ UAPageName }));
            }
            if (pageName) {
                dispatch(stateController.setState({ pageName }));
            }
            if (playerId) {
                dispatch(stateController.setState({ playerId }));
            }

            dispatch(stateController.setState({ isOpen: true, step: 1 }));

        }
    }

    public static getInTouch() {
        return async (dispatch, getState: () => AppState) => {
            // const { UAPageName, playerId } = getState().upgradeSubscriptionPopup;
            const { pageName } = getState().upgradeSubscriptionPopup;
            const userId = getState().auth.userId;
            const squadId = getState().auth.squadId;

            dispatch(stateController.setState({ isBusy: true }));
            // await PermissionsService.sendPermissionRequest(squadId, userId, "Get in touch", pageName);
            await simulateServerCall(null, 2000) //TODO: need to be removed all async function
            dispatch(stateController.setState({ isBusy: false, step: 2 }));

            // switch (Boolean(UAPageName) && UAPageName) {
            //     case 'Search [Highlight]':
            //         dispatch(Actions.sendUserActivity(UAPageName, 'Sent Upgrade Request'));
            //         break;
            //     case 'Player Profile [Restriction]':
            //         dispatch(Actions.sendUserActivity(UAPageName, 'Get in Touch', squadId, playerId));
            //         break;
            //     default:
            //         dispatch(Actions.sendUserActivity(UAPageName, 'Get in Touch'));
            // }
        }
    }

    public static sendUserActivity = (pageName: string, message: string, clubId?: number, playerId?: number) => {
        return (dispatch) => {
            dispatch(userActivityInsert({
                PageName: pageName,
                Message: message,
                PlayerId: playerId ? playerId : undefined,
                ClubId: clubId ? clubId : undefined,
            }));
        }
    };
}

class Selectors {
    public static getRoot = (state: AppState) => state.upgradeSubscriptionPopup
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    UpgradeSubscriptionPopupState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};



