import axios from 'axios-config';
import {
    MySquadToBuy,
    MySquadToLoan,
    UpdatePlayerRequest,
} from 'api/transfers-out/models/transfers-out-player-datails.model';

export default class TransfersOutPlayerDatailsService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async saveVideos(playerId: number, videos: Array<string>): Promise<any> {
        const { data } = await axios.post(
            `api/Players/${playerId}/SaveVideos`,
            videos.map((x) => ({ url: x })),
            this.axiosConfig
        );
        return data;
    }

    public static async updatePlayers(items: Array<UpdatePlayerRequest>) {
        const { data } = await axios.post(`api/v2/MySquad/UpdatePlayers`, items, this.axiosConfig);
        return data;
    }

    public static async saveAvailability(
        playerId: number,
        toBuy: MySquadToBuy,
        toLoan: MySquadToLoan
    ) {
        const { data } = await axios.post(
            `api/v2/MySquad/SetAvailability`,
            { playerId: playerId, toBuy: toBuy, toLoan: toLoan },
            this.axiosConfig
        );
        return data;
    }

    public static async saveAvailabilityAndGetClubAds(
        playerId: number,
        toBuy: MySquadToBuy,
        toLoan: MySquadToLoan
    ) {
        const { data } = await axios.post(
            `api/v2/MySquad/SetAvailabilityAndGetClubAds`,
            { playerId: playerId, toBuy: toBuy, toLoan: toLoan },
            this.axiosConfig
        );
        return data;
    }

    public static async quickSearchFlush() {
        const { data } = await axios.get(`api/v2/MySquad/QuickSearchFlush`, this.axiosConfig);
        return data;
    }

    public static async deletePlayer(playerId: number, reason: string) {
        const { data } = await axios.post(
            `api/v2/MySquad/RemovePlayer`,
            { playerId: playerId, reason: reason },
            this.axiosConfig
        );
        return data;
    }

    public static async loadRecommendedPlayersBanner(): Promise<any> {
        const { data } = await axios.get(`api/v2/MySquad/ShowBanner`, this.axiosConfig);
        return data;
    }
    public static async hideRecommendedPlayersBanner(): Promise<any> {
        const { data } = await axios.post(`api/v2/MySquad/CloseBanner`, this.axiosConfig);
        return data;
    }

    public static async matchMakerFetch(squadId: number, userId: number): Promise<any> {
        const { data } = await axios.get(
            `api/MySquad/GetMySquadRecommendationPerPlayer/${squadId}/${userId}`,
            this.axiosConfig
        );
        return data;
    }

    public static async pitchPlayer(userId: number, playerAdId: number, amount: number, playerId: number, squadId?: number, squadName?: string): Promise<any> {
        await axios.post(
            `api/ClubAds/PitchPlayer`,
            { userId: userId, playerAdId: playerAdId, amount: amount, playerId: playerId, squadName: squadName, squadId: squadId },
            this.axiosConfig
        );
    }
}
