import constants from "../services/constants";

export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toKMB(val) {
    var labelValue = Math.round(val * 1000) / 1000;
    var result = Math.abs(Number(labelValue)) >= 1.0e9
        ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + 'B'
        : Math.abs(Number(labelValue)) >= 1.0e6
            ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + 'M'
            : Math.abs(Number(labelValue)) >= 1.0e3
                ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + 'k'
                : Math.abs(Number(labelValue));

    return result.toString().replace('.0', '');
}

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const validateFee = (fee) => {
    let val = fee.replace(/\D/g, '')
    val = val.substring(0, 9)
    if (val[0] === '0' && val[1]) val = val[1];

    if (val > constants.transferFee.max) val = constants.transferFee.max

    return  numberWithCommas(val);
}

export const removeCommasFromNumber = (x) => {
    if (x === null || x === undefined) {
        return null;
    }

    return Number(x.replace(/\,/g,''));
}

export const roundToTwoDecimals = (num) => {
    if (typeof num !== 'number') {
        return null;
    }
    if (num === 0) {
        return 0;
    }
    const multipliedNumber = num * 100;
    const roundedNumber = Math.round(multipliedNumber);
    return (roundedNumber / 100).toFixed(2);
}
export const convertStringToNumber = (input) => {

    if (input == null) {
        return null
    }
    
    if (typeof input !== 'string') {
        throw new Error('Input must be a string');
    }
    
    const cleanedInput = input.replace(/[^0-9.]/g, '');
    
    const numericValue = parseFloat(cleanedInput);
    
    if (isNaN(numericValue)) {
        return null;
    }
    
    return numericValue;
}