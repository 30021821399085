export interface MySquadToBuy {
    request: boolean;
    askingPrice: number;
    sellOn: number;
}

export interface MySquadToLoan {
    request: boolean;
    monthlyLoan: number;
}

export interface UpdatePlayerRequest {
    playerId: number;
    contractExpiration: Date;
    firstPositionCode: string;
    secondPositionCode: string;
}

export enum PlayerPositionTypeEnum {
    PrimaryPosition = 0,
    SecondaryPosition = 1,
}
