import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import {
    MySquadPlayer,
    SubSquadFilterTypesEnum,
    MySquadTitle,
    GroupedSquadOpportunities,
    OpportunityItem,
} from 'api/transfers-out/models/transfers-out';
import TransfersOutService from 'api/transfers-out/transfers-out.service';
import { Actions as PlayerDetailsController } from 'pages/club/transfers-out/redux/transfer-out-player-detail.controller';
import { getAuthSquadId, isClubCanPitchPlayersEnabled } from 'store/auth/authReducer';
import { getAuth } from 'store/auth/authReducer';
import { allPositionKeys } from 'utils/position-helper';
import { PlayerAvailabilityStatusEnum } from 'api/transfers-out/models/transfers-out';
import { Actions as UpgradeSubscriptionController } from 'ts-components-v2/upgrade-subscription-popup/upgrade-subscription-popup.controller';
import { MySquadToBuy, MySquadToLoan } from 'api/my-squad/model/my-squad-player';

const AllPlayers: number = -10;
const OutOfLoan: number = -20;
class TransfersOutMySquadState {
    isLoading: boolean;
    mySquad: MySquadPlayer[];
    squads: MySquadTitle[];
    activePlayerId: number;
    filteredMySquad: MySquadPlayer[];
    showBunner: boolean;
    recommendedPlayersIds: number[];
    filterSquadId: number;
    filterSubSquad: SubSquadFilterTypesEnum[];
    playersMarkedNotAvailableIds: number[];
    processing: boolean;
    processingIds: number[];

    isMatchMakerBusy: boolean;
    playersMatchMaker: object;
    playersMatchMakerCounters: object;
}

const defaultState: TransfersOutMySquadState = {
    isLoading: false,
    mySquad: [],
    squads: [],
    activePlayerId: null,
    filteredMySquad: [],
    showBunner: false,
    recommendedPlayersIds: [],
    filterSquadId: null,
    filterSubSquad: [],
    processing: false,
    processingIds: [],

    playersMarkedNotAvailableIds: [],
    isMatchMakerBusy: false,
    playersMatchMaker: {},
    playersMatchMakerCounters: {},
};

const stateController = new StateController<TransfersOutMySquadState>(
    'SQUAD_TRANSFER_OUT/MY_SQUAD',
    defaultState
);

class Actions {
    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState(defaultState));
        };
    }

    public static initMySquadData() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));

            try {
                let appState = getState();
                const { canUseMatchMaker, adsCanSeeClubNames } = appState.auth.clubPermission;

                if (canUseMatchMaker && adsCanSeeClubNames) {
                    dispatch(Actions.matchMakerFetch());
                }

                let subSquads = appState.auth.squadIds;
                let withApiIds = appState.auth.squadIdWithApiIds;

                const data = await TransfersOutService.getMySquad();

                subSquads.sort((a, b) => {
                    let findA = withApiIds[a];
                    let findB = withApiIds[b];
                    if (findA && findB) {
                        return Math.abs(findA) - Math.abs(findB);
                    }
                    return 0;
                });

                let squadSource = data.players.map((x) => x.currentSquad);
                let squads = new Array<MySquadTitle>();

                squads.push({ id: AllPlayers, name: 'All Players', count: data.players.length });

                if (subSquads.length > 1) {
                    subSquads.forEach((squad) => {
                        let squadFind = squadSource.find((s) => s.id == squad);
                        if (squadFind) {
                            let count = data.players.filter(
                                (x) =>
                                    x.currentSquad.id == squad ||
                                    (x.parentSquad && x.parentSquad.id == squad)
                            ).length;

                            squads.push({ id: squad, name: squadFind.name, count: count });
                        }
                    });
                }

                const outOfLoanPlayersQty = data.players.filter((x) => x.loanedOut).length;

                if (outOfLoanPlayersQty > 0) {
                    squads.push({ id: OutOfLoan, name: 'Out on loan', count: outOfLoanPlayersQty });
                }

                const showBunner = await TransfersOutService.loadRecommendedPlayersBanner();

                const recommendedPlayersIds = data.players
                    .filter(
                        (player) =>
                            player.isRecommended === true &&
                            player.toBuy === null &&
                            player.toLoan === null
                    )
                    .map((player) => player.id);
                dispatch(
                    stateController.setState({ recommendedPlayersIds: recommendedPlayersIds })
                );

                const currentSquadId = getAuthSquadId(getState());
                const opportunities = getState().transfersOut.common.opportunities;
                const groupedSquadOpportunities = Helpers.groupByPlayerId(
                    opportunities,
                    currentSquadId
                );

                const players = data.players.map((player) => ({
                    ...player,
                    matches: groupedSquadOpportunities[player.id]?.length ?? 0,
                }));

                if (recommendedPlayersIds.length > 0) {
                    dispatch(
                        stateController.setState({
                            filterSubSquad: [SubSquadFilterTypesEnum.Recommended],
                        })
                    );
                }

                dispatch(
                    stateController.setState({
                        mySquad: players,
                        squads: squads,
                        filterSquadId: AllPlayers,
                        playersMarkedNotAvailableIds: data.playersMarkedNotAvailable,
                    })
                );

                dispatch(Actions.applyFilters());
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        };
    }

    public static setSquadFilter(squadId: number) {
        return (dispatch) => {
            dispatch(stateController.setState({ filterSquadId: squadId }));
            dispatch(Actions.applyFilters());
        };
    }

    public static setSubSquadFilter(e: any) {
        return (dispatch) => {
            if (e.target.checked) {
                dispatch(stateController.setState({ filterSubSquad: [e.target.id] }));
            } else {
                dispatch(stateController.setState({ filterSubSquad: [] }));
            }
            dispatch(Actions.applyFilters());
        };
    }

    public static applyFilters() {
        return (dispatch, getState: () => AppState) => {
            const filteredPlayersList = Selectors.getFilteredPlayersList(getState());
            dispatch(
                stateController.setState({
                    filteredMySquad: filteredPlayersList,
                    activePlayerId: null,
                })
            );
        };
    }

    public static matchMakerFetch() {
        return async (dispatch, getState: () => AppState) => {
            const appState = getState();
            const { squadId, userId } = getAuth(appState);
            dispatch(stateController.setState({ isMatchMakerBusy: true }));
            try {
                const data = await TransfersOutService.matchMakerFetch(squadId, userId);
                const playersMatchMakerCounters = {};
                for (const key in data) {
                    if (data.hasOwnProperty(key)) {
                        const element = data[key];
                        playersMatchMakerCounters[key] = element.length;
                    }
                }

                dispatch(
                    stateController.setState({
                        playersMatchMaker: data,
                        playersMatchMakerCounters,
                    })
                );
            } catch (e) {
                console.error(e);
            } finally {
                dispatch(stateController.setState({ isMatchMakerBusy: false }));
            }
        };
    }

    public static setActivePlayer(player: MySquadPlayer) {
        return (dispatch, getState: () => AppState) => {
            const processingId = Selectors.getActivePlayerId(getState());
            if (!!processingId && processingId === player.id) {
                dispatch(stateController.setState({ activePlayerId: null }));
                dispatch(PlayerDetailsController.setSelectedPlayer(null));
                dispatch(PlayerDetailsController.showPlayerInfo(false));
            } else {
                dispatch(stateController.setState({ activePlayerId: player.id }));
                dispatch(PlayerDetailsController.setSelectedPlayer(player));
                dispatch(PlayerDetailsController.showPlayerInfo(true));
            }
        };
    }

    public static makePlayerNotAvailable(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ processing: true }));

            try {
                dispatch(stateController.setState({ processingIds: [playerId] }));
                dispatch(
                    stateController.setState((prevState) => ({
                        ...prevState,
                        playersMarkedNotAvailableIds: [
                            ...prevState.playersMarkedNotAvailableIds,
                            playerId,
                        ],
                        recommendedPlayersIds: [
                            ...prevState.recommendedPlayersIds.filter((id) => id !== playerId),
                        ],
                        mySquad: [
                            ...prevState.mySquad.map((player) =>
                                player.id === playerId
                                    ? { ...player, isRecommended: false }
                                    : player
                            ),
                        ],
                        filteredMySquad: [
                            ...prevState.filteredMySquad.filter((player) => player.id !== playerId),
                        ],
                    }))
                );

                await TransfersOutService.makePlayerNotAvailable(playerId);
                // await dispatch(Actions.initMySquadData())
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(
                    stateController.setState({
                        processing: false,
                        processingIds: [],
                    })
                );
            }
        };
    }

    public static makePlayerAvailable(player: MySquadPlayer) {
        return async (dispatch, getState: () => AppState) => {
            const isAllowed = Selectors.isAllowedToPitchPlayers(getState());
            if (isAllowed) {
                dispatch(Actions.setActivePlayer(player));
            } else {
                dispatch(UpgradeSubscriptionController.openModal());
            }
        };
    }

    public static makePlayerNotRecommended(
        playerId: number,
        avaliabilityStatus: PlayerAvailabilityStatusEnum,
        toBuy: MySquadToBuy,
        toLoan: MySquadToLoan
    ) {
        return async (dispatch, getState: () => AppState) => {
            let playersList = getState().transfersOut.mySquad.mySquad;
            let recommendedPlayersIds = getState().transfersOut.mySquad.recommendedPlayersIds;
            const player = playersList.find((player) => player.id === playerId);

            if (player) {
                player.isRecommended = false;
                player.availabilityStatus = avaliabilityStatus;
                player.toBuy = toBuy;
                player.toLoan = toLoan;
                dispatch(PlayerDetailsController.setSelectedPlayer(player));
                dispatch(PlayerDetailsController.showPlayerInfo(true));
            }

            recommendedPlayersIds = recommendedPlayersIds.filter((id) => id !== playerId);

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    mySquad: [...playersList],
                    recommendedPlayersIds: [...recommendedPlayersIds],
                }))
            );
        };
    }

    public static updatePlayer(player: MySquadPlayer) {
        return async (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState());
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    mySquad: [
                        ...substate.mySquad.map((item) => (item.id === player.id ? player : item)),
                    ],
                }))
            );
            const filtered = Selectors.getFilteredPlayersList(getState());
            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    filteredMySquad: [...filtered],
                }))
            );
        };
    }

    public static removePlayer(playerId: number) {
        return async (dispatch, getState: () => AppState) => {
            const substate = Selectors.getRoot(getState()).mySquad;

            const updatedSquad = substate.filter((player) => player.id !== playerId);

            dispatch(
                stateController.setState((prevState) => ({
                    ...prevState,
                    mySquad: [...updatedSquad],
                }))
            );
        };
    }
}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersOut.mySquad;
    public static getFilteredMySquadPlayers = (state: AppState) =>
        Selectors.getRoot(state).filteredMySquad;
    public static getFilterSubSquad = (state: AppState) => Selectors.getRoot(state).filterSubSquad;
    public static getSelectedSquadId = (state: AppState) => Selectors.getRoot(state).filterSquadId;
    public static getRecommendedPlayersIds = (state: AppState) =>
        Selectors.getRoot(state).recommendedPlayersIds;
    public static getActivePlayerId = (state: AppState) => Selectors.getRoot(state).activePlayerId;
    public static getSquads = (state: AppState) => state.transfersOut.mySquad.squads;
    public static getPlayersMarkedNotAvailableIds = (state: AppState) =>
        state.transfersOut.mySquad.playersMarkedNotAvailableIds;
    public static getFilteredBySubSquadPlayersList = (state: AppState) => {
        const subState = Selectors.getRoot(state);
        let items = subState.mySquad;
        if (subState.filterSquadId !== AllPlayers) {
            if (subState.filterSquadId === OutOfLoan) {
                items = [...items.filter((player) => player.loanedOut)];
            } else {
                items = [
                    ...items.filter(
                        (player) =>
                            player.currentSquad.id == subState.filterSquadId ||
                            (player.parentSquad && player.parentSquad.id === subState.filterSquadId)
                    ),
                ];
            }
        }

        return items;
    };
    public static getFilteredPlayersList = (state: AppState, filter?: SubSquadFilterTypesEnum) => {
        const subState = Selectors.getRoot(state);
        const subSquadFilter = filter ? filter : Selectors.getFilterSubSquad(state)?.toString();

        let items: MySquadPlayer[] = subState.mySquad;

        if (subState.filterSquadId !== AllPlayers) {
            if (subState.filterSquadId === OutOfLoan) {
                items = [...items.filter((player) => player.loanedOut)];
            } else {
                items = [
                    ...items.filter(
                        (player) =>
                            player.currentSquad.id == subState.filterSquadId ||
                            (player.parentSquad && player.parentSquad.id === subState.filterSquadId)
                    ),
                ];
            }
        }

        if (subSquadFilter === SubSquadFilterTypesEnum.Recommended) {
            items = [...items.filter((player) => player.isRecommended)];
        }

        if (subSquadFilter === SubSquadFilterTypesEnum.NotAvailable) {
            items = [
                ...items.filter(
                    (player) =>
                        player.availabilityStatus === PlayerAvailabilityStatusEnum.NotAvailable
                ),
            ];
        }

        if (subSquadFilter === SubSquadFilterTypesEnum.Available) {
            items = [
                ...items.filter(
                    (player) =>
                        player.availabilityStatus === PlayerAvailabilityStatusEnum.ToBuy ||
                        player.availabilityStatus === PlayerAvailabilityStatusEnum.ToLoan ||
                        player.availabilityStatus === PlayerAvailabilityStatusEnum.ToBuyAndLoan
                ),
            ];
        }

        if (subSquadFilter === SubSquadFilterTypesEnum.Goalkeepers) {
            items = [
                ...items.filter(
                    (player) =>
                        player?.firstPositionCode === allPositionKeys.Goalkeeper ||
                        player?.secondPositionCode === allPositionKeys.Goalkeeper
                ),
            ];
        }

        if (subSquadFilter === SubSquadFilterTypesEnum.Attackers) {
            items = [
                ...items.filter(
                    (player) =>
                        player?.firstPositionCode === allPositionKeys.Winger ||
                        player?.firstPositionCode === allPositionKeys.Forward ||
                        player?.secondPositionCode === allPositionKeys.Winger ||
                        player?.secondPositionCode === allPositionKeys.Forward
                ),
            ];
        }

        if (subSquadFilter === SubSquadFilterTypesEnum.Midfielders) {
            items = [
                ...items.filter(
                    (player) =>
                        player?.firstPositionCode === allPositionKeys.DefensiveMidfielder ||
                        player?.firstPositionCode === allPositionKeys.CentralMidfielder ||
                        player?.firstPositionCode === allPositionKeys.AttackingMidfielder ||
                        player?.secondPositionCode === allPositionKeys.DefensiveMidfielder ||
                        player?.secondPositionCode === allPositionKeys.CentralMidfielder ||
                        player?.secondPositionCode === allPositionKeys.AttackingMidfielder
                ),
            ];
        }

        if (subSquadFilter === SubSquadFilterTypesEnum.Defenders) {
            items = [
                ...items.filter(
                    (player) =>
                        player?.firstPositionCode === allPositionKeys.LeftBack ||
                        player?.firstPositionCode === allPositionKeys.CentreBack ||
                        player?.firstPositionCode === allPositionKeys.RightBack ||
                        player?.secondPositionCode === allPositionKeys.LeftBack ||
                        player?.secondPositionCode === allPositionKeys.CentreBack ||
                        player?.secondPositionCode === allPositionKeys.RightBack
                ),
            ];
        }

        return items;
    };
    public static getProcessingStatus = (state: AppState) => Selectors.getRoot(state).processing;
    public static getgetProcessingIds = (state: AppState) => Selectors.getRoot(state).processingIds;
    public static isAllowedToPitchPlayers = (state: AppState) =>
        isClubCanPitchPlayersEnabled(state);
}

class Helpers {
    public static groupByPlayerId(inputObj: OpportunityItem[], squadId?: number) {
        let outputObj: GroupedSquadOpportunities = {};

        for (let index = 0; index < inputObj.length; index++) {
            const element = inputObj[index];
            if (element.playerAd.createdBySquad.id === squadId) {
                continue;
            }

            element.playersWithRelevance.forEach((playerRelevance) => {
                const playerId = playerRelevance.player.id;

                if (!outputObj[playerId]) {
                    outputObj[playerId] = [];
                }

                outputObj[playerId].push(element);
            });
        }

        return outputObj;
    }
}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersOutMySquadState as State,
    Actions as Actions,
    stateController as Controller,
};
