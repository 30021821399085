import { PlayerAvailabilityStatusEnum } from 'api/transfers-out/models/transfers-out';
import { MySquadToBuy, MySquadToLoan } from 'api/my-squad/model/my-squad-player';

export const getToByToLoanAvalibilityStatus = (toBuy: MySquadToBuy, toLoan: MySquadToLoan) => {
    if (toBuy != null && toLoan != null) {
        return PlayerAvailabilityStatusEnum.ToBuyAndLoan;
    }

    if (toBuy != null && toLoan == null) {
        return PlayerAvailabilityStatusEnum.ToBuy;
    }

    if (toBuy == null && toLoan != null) {
        return PlayerAvailabilityStatusEnum.ToLoan;
    }

    return PlayerAvailabilityStatusEnum.NotAvailable;
};
