import { StateController } from 'utils/action-declaration'
import { AppState } from 'root.reducer'
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import historyAccessor from 'history-accessor';

class LayoutState {
    isSideBarCollapsed: boolean;
    isHamburgerMenuOpen: boolean | null;
    isLoginDropdownOpen: boolean;

}

const defaultState: LayoutState = {
    isSideBarCollapsed: false,
    isHamburgerMenuOpen: null,
    isLoginDropdownOpen: false,
}

const stateController = new StateController<LayoutState>(
    'LAYOUT',
    defaultState
)

class Actions {

    public static dispose() {
        return (dispatch) => {
            dispatch(stateController.setState({ ...defaultState }));
        };
    }

    public static setSidebarCollapsed() {
		return (dispatch, getState: () => AppState) => {
            const isCollapsed = getState().layout.isSideBarCollapsed
			dispatch(stateController.setState({isSideBarCollapsed: !isCollapsed}));
		}
	}

    public static setHamburgerMenu() {
		return (dispatch, getState: () => AppState) => {
            const isOpen = getState().layout.isHamburgerMenuOpen
			dispatch(stateController.setState({isHamburgerMenuOpen: !isOpen}));
		}
	}

    public static toggleLoginDropdown() {
		return (dispatch, getState: () => AppState) => {
            const isDropdown = getState().layout.isLoginDropdownOpen;
            const isHamburgerMenuOpen = getState().layout.isHamburgerMenuOpen

            if (isHamburgerMenuOpen) {
                dispatch(stateController.setState({isHamburgerMenuOpen: null}));
            }

			dispatch(stateController.setState({isLoginDropdownOpen: !isDropdown}));
		}
	}
    
    public static sendUserActivity(message: string, agencyId: number) {
		return (dispatch, getState: () => AppState) => {
			// const { playerId } = getAuth(getState());
			//
			// dispatch(userActivityInsert({
			// 	PageName: 'Agent Confirmation',
			// 	Message: message,
			// 	PlayerId: playerId,
			// 	AgencyId: agencyId,
			// }))
		}
	}
}

class Selectors {
    public static getRoot = (state: AppState) => state.layout
}   

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    LayoutState as State,
    Actions as Actions,
    Selectors as Selectors,
    stateController as Controller
};