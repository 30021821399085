import axios from 'axios-config';

export class PlusPitchService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async testInterest(plusPitchSquadId: number) {
        await axios.get(`api/PlusPitch/TestInterest/${plusPitchSquadId}`, {
            ...this.axiosConfig,
        });
    }
}
