import { AppState } from 'root.reducer';
import { StateController } from 'utils/action-declaration';
import { OpportunityItem, PitchItem } from 'api/transfers-out/models/transfers-out';
import TransfersOutService from 'api/transfers-out/transfers-out.service';
import historyAccessor from 'history-accessor';
import { userPaths } from 'routes/paths';
import { simulateServerCall } from 'utils/simulate-server-call';
import { isClubCanPitchPlayersEnabled } from 'store/auth/authReducer';
import { Actions as UpgradeSubscriptionController } from 'ts-components-v2/upgrade-subscription-popup/upgrade-subscription-popup.controller';

class TransfersOutTopOpportunitiesState {
    isLoading: boolean;
    opportunities: OpportunityItem[];
    pitches: PitchItem[];
    processingOpportunityIds: number[];
    processingPitchIds: number[];
}

const defaultState: TransfersOutTopOpportunitiesState = {
    isLoading: false,
    opportunities: [],
    pitches: [],
    processingOpportunityIds: [],
    processingPitchIds: [],
}

const stateController = new StateController<TransfersOutTopOpportunitiesState>(
    'SQUAD_TRANSFER_OUT/TOP_OPPORTUNITIES',
    defaultState
);

class Actions {
    public static dispose() {
        return dispatch => {
            dispatch(stateController.setState(defaultState))
        }
    }

    public static initTopOpportunities() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ isLoading: true }));
            try {
                const data = getState().transfersOut.common;
                const pitches = await TransfersOutService.getSavedPitches();

                dispatch(stateController.setState({
                    opportunities: data.opportunities,
                    pitches: [...pitches],
                }));

            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isLoading: false }));
            }
        }
    }

    public static dismissPitchOpportunity = (createdBySquad: number, playerAdId: number) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                // dispatch(stateController.setState({ isOpportunityProcessing: true }));
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingOpportunityIds: [...prevState.processingOpportunityIds, playerAdId]
                })))

                await TransfersOutService.opportunityDecline(createdBySquad, playerAdId);
                // // await dispatch(Actions.getInitialData());                
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    opportunities: prevState.opportunities.filter(item => item.playerAd.id !== playerAdId)
                })))

            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    // isOpportunityProcessing: false,
                    processingOpportunityIds: prevState.processingOpportunityIds.filter(item => item !== playerAdId)
                })))
            }
        }
    }

    public static dismissPitch = (playerId: number) => {
        return async (dispatch, getState: () => AppState) => {
            try {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingPitchIds: [...prevState.processingPitchIds, playerId]
                })))

                await simulateServerCall(null, 1000) //TODO: need to be removed all async function
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    pitches: prevState.pitches.filter(item => item.playerId !== playerId)
                })))
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState(prevState => ({
                    ...prevState,
                    processingPitchIds: prevState.processingPitchIds.filter(item => item !== playerId)
                })))
            }
        }
    }

    public static openPitchInsightsPage() {
        return (dispatch, getState: () => AppState) => {
            historyAccessor.push(userPaths.pitchInsights);   //TODO: need to be changed
        }
    }
    public static openPitchPlayerPage() {
        return (dispatch, getState: () => AppState) => {
            const isAllowed = Selectors.isAllowedToPitchPlayers(getState())
            if (isAllowed) {
                alert('pitch player')
            // historyAccessor.push(userPaths.pitchPage);
            } else {
                dispatch(UpgradeSubscriptionController.openModal())
            }
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState) => state.transfersOut.topOpportunities
    public static getOpportunities = (state: AppState) => Selectors.getRoot(state).opportunities;
    public static getPitchesList = (state: AppState) => Selectors.getRoot(state).pitches;
    public static getItemsList = (state: AppState) => {
        const opportunities = Selectors.getOpportunities(state)
        const pitches = Selectors.getPitchesList(state)
        
        return [...pitches, ...opportunities]
    };
    public static getProcessingOpportunityIds = (state: AppState) => Selectors.getRoot(state).processingOpportunityIds;
    public static getProcessingPitchIds = (state: AppState) => Selectors.getRoot(state).processingPitchIds;
    public static isAllowedToPitchPlayers = (state: AppState) => isClubCanPitchPlayersEnabled(state);

}

const reducer = stateController.getReducer();

export {
    Selectors as Selectors,
    reducer as Reducer,
    TransfersOutTopOpportunitiesState as State,
    Actions as Actions,
    stateController as Controller
};