import { AppState } from 'root.reducer';
import { CancelTokenSource } from 'axios';
import { StateController } from 'utils/action-declaration';
import SearchService from 'api/search-v3/search.clubside.service';
import { SearchPlayerPosition, SearchPlayerPositionEnum } from "api/search-v3/model/player-positions";
import { Actions as GridActions } from 'pages/club/pitch-opportunities/redux/grid.controller';
import userActivityInsert from 'app/user-activity/actions/user-activity.actions';
import { PageType } from 'constants/enums';
import { getPositionCodeByName } from 'utils/position-helper';
import positionFullNames from 'constants/positionFullNames';
import { PlayerAdTypeEnumModel } from 'api/core/pitch-opportunity';
import Range from 'api/core/range';
import MarketValueSelector from 'components/Forms/MarketValueSelector/index';

export class FilterOpportunitiesState {
    positionFilter: SearchPlayerPositionEnum | null;
    positionFilterModal: any;
    positions: SearchPlayerPosition[];
    sortToBuy: PlayerAdTypeEnumModel;
    sortToLoan: PlayerAdTypeEnumModel;
    isPositionsLoading: boolean;
    transferTypeBudgetModal: {
        isTransferTypeBudgetModalOpen: boolean;
        transferFee: Range<number>;
        annualGrossSalary: Range<number>;
        loanFee: Range<number>;
    }
    playerFilterModal: {
        isPlayerFilterModalOpen: boolean;
        selectedPlayer: number;
    }
}

const defaultState: FilterOpportunitiesState = {
    positionFilter: null,
    positionFilterModal: null,
    positions: [],
    sortToBuy: null,
    sortToLoan: null,
    isPositionsLoading: false,
    transferTypeBudgetModal: {
        isTransferTypeBudgetModalOpen: false,
        transferFee: {
            min: 0,
            max: 500000000,
        },
        annualGrossSalary: {
            min: 0,
            max: 500000000,
        },
        loanFee: {
            min: 0,
            max: 500000000,
        }
    },
    playerFilterModal: {
        isPlayerFilterModalOpen: false,
        selectedPlayer: null,
    }
}

const stateController = new StateController<FilterOpportunitiesState>(
    'CLUB_PITCH_OPPORTUNITIES/FILTER',
    defaultState,
);

class Actions {
    public static token: CancelTokenSource = null;

    public static dispose() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState({ ...defaultState }))
        }
    }

    public static sendUserActivity(message: string, pageName: string) {
        return (dispatch, getState: () => AppState) => {
            dispatch(userActivityInsert({
                PageName: pageName,
                Message: message,
                PageType: PageType.Search,
            }));
        }
    }

    public static loadPlayerPositions = () => {
        return async (dispatch) => {
            try {
                dispatch(stateController.setState({ isPositionsLoading: true }));
                const { positions } = await SearchService.getPlayerPositions();
                dispatch(stateController.setState({ positions }));
            } catch (error) {
                console.error(error);
            } finally {
                dispatch(stateController.setState({ isPositionsLoading: false }));
            }
        }
    }

    public static onClearAllClick() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.clearAllFilters());
            await dispatch(GridActions.refresh());
        }
    }

    public static clearAllFilters() {
        return async (dispatch, getState: () => AppState) => {
            dispatch(Actions.clearPositionFilter());
            dispatch(Actions.disposeTransferTypeBudgetModal());
            dispatch(Actions.clearPlayerAdTypeFilters());
            dispatch(Actions.clearPlayerFilter());
        }
    }

    // --------------- Transfer Type and Budget Filters  ---------- //
    public static openTransferTypeBudgetModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.toggleTransferTypeBudgetModal(true))
        }
    }

    public static closeTransferTypeBudgetModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.disposeTransferTypeBudgetModal());
        }
    }

    public static applyFiltersTransferTypeBudgetModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(GridActions.applyFilters());

            dispatch(Actions.toggleTransferTypeBudgetModal(false))
        }
    }

    public static toggleTransferTypeBudgetModal(isOpen: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    isTransferTypeBudgetModalOpen: isOpen,
                }
            })));
        }
    }

    public static disposeTransferTypeBudgetModalFilters() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.disposeTransferTypeBudgetModal());

            dispatch(GridActions.applyFilters());
        }
    }

    public static disposeTransferTypeBudgetModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: defaultState.transferTypeBudgetModal
            })));
        }
    }

    public static setTransferFeeMin(value: number) {
        return (dispatch, getState: () => AppState) => {
            if (value > defaultState.transferTypeBudgetModal.transferFee.max) {
                return;
            }
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    transferFee: {
                        ...prevState.transferTypeBudgetModal.transferFee,
                        min: value,
                    },
                }
            })));
        }
    }

    public static setTransferFeeMax(value: number) {
        return (dispatch, getState: () => AppState) => {
            if (value > defaultState.transferTypeBudgetModal.transferFee.max) {
                return;
            }
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    transferFee: {
                        ...prevState.transferTypeBudgetModal.transferFee,
                        max: value,
                    },
                }
            })));
        }
    }

    public static onChangeTransferFee(value: Range<number>) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    transferFee: {
                        ...prevState.transferTypeBudgetModal.transferFee,
                        min: Number(value.min),
                        max: Number(value.max)
                    },
                }
            })));
        }
    }

    public static setAnnualGrossSalaryMin(value: number) {
        return (dispatch, getState: () => AppState) => {
            if (value > defaultState.transferTypeBudgetModal.annualGrossSalary.max) {
                return;
            }
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    annualGrossSalary: {
                        ...prevState.transferTypeBudgetModal.annualGrossSalary,
                        min: value,
                    },
                }
            })));
        }
    }

    public static setAnnualGrossSalaryMax(value: number) {
        return (dispatch, getState: () => AppState) => {
            if (value > defaultState.transferTypeBudgetModal.transferFee.max) {
                return;
            }
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    annualGrossSalary: {
                        ...prevState.transferTypeBudgetModal.annualGrossSalary,
                        max: value,
                    },
                }
            })));
        }
    }

    public static onChangeAnnualGrossSalary(value: Range<number>) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    annualGrossSalary: {
                        ...prevState.transferTypeBudgetModal.annualGrossSalary,
                        min: Number(value.min),
                        max: Number(value.max)
                    },
                }
            })));
        }
    }

    public static setLoanFeeMin(value: number) {
        return (dispatch, getState: () => AppState) => {
            if (value > defaultState.transferTypeBudgetModal.loanFee.max) {
                return;
            }
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    loanFee: {
                        ...prevState.transferTypeBudgetModal.loanFee,
                        min: value,
                    },
                }
            })));
        }
    }

    public static setLoanFeeMax(value: number) {
        return (dispatch, getState: () => AppState) => {
            if (value > defaultState.transferTypeBudgetModal.loanFee.max) {
                return;
            }
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    loanFee: {
                        ...prevState.transferTypeBudgetModal.loanFee,
                        max: value,
                    },
                }
            })));
        }
    }

    public static onChangeLoanFee(value: Range<number>) {
        return (dispatch, getState: () => AppState) => {

            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    loanFee: {
                        ...prevState.transferTypeBudgetModal.loanFee,
                        min: Number(value.min),
                        max: Number(value.max)
                    },
                }
            })));
        }
    }

    public static clearToBuyParams() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    transferFee: defaultState.transferTypeBudgetModal.transferFee,
                    annualGrossSalary: defaultState.transferTypeBudgetModal.annualGrossSalary,
                }
            })));
            dispatch(GridActions.applyFilters());
        }
    }

    public static clearToLoanParam() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                transferTypeBudgetModal: {
                    ...prevState.transferTypeBudgetModal,
                    loanFee: defaultState.transferTypeBudgetModal.loanFee,
                }
            })));
            dispatch(GridActions.applyFilters());
        }
    }

    // ________________________________________________________________________________

    // -------------------------Player Filter Modal------------------------------------
    public static togglePlayerFilterModal(isOpen: boolean) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerFilterModal: {
                    ...prevState.playerFilterModal,
                    isPlayerFilterModalOpen: isOpen,
                }
            })));
        }
    }

    public static openPlayerFilterModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.togglePlayerFilterModal(true))
        }
    }

    public static closePlayerFilterModal() {
        return (dispatch, getState: () => AppState) => {
            dispatch(Actions.togglePlayerFilterModal(false));
        }
    }

    public static setPlayer(id: number) {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerFilterModal: {
                    ...prevState.playerFilterModal,
                    selectedPlayer: id,
                }
            })));
        }
    }

    public static clearPlayerFilter() {
        return (dispatch, getState: () => AppState) => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                playerFilterModal: {
                    ...prevState.playerFilterModal,
                    selectedPlayer: null,
                }
            })));
            dispatch(Actions.togglePlayerFilterModal(false));
            dispatch(GridActions.applyFilters());
        }
    }

    public static applyPlayerFilter() {
        return (dispatch, getState: () => AppState) => {
            const selectedPlayer = Selectors.getPlayerFilterModalState(getState()).selectedPlayer
            dispatch(GridActions.applyFilters());
            dispatch(Actions.togglePlayerFilterModal(false));
            dispatch(GridActions.setRelevancePlayer(selectedPlayer));
        }
    }

    // __________________________________________________________________________________

    // ------------------ Position Filters  --------------- //

    public static togglePositionFilterModal(isOpen: boolean) {
        return (dispatch, getState: () => AppState) => {
            const position = Selectors.getPositionFilter(getState())
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                positionFilterModal: isOpen ? { position } : null,
            })));

            if (isOpen) {
                // dispatch(userActivityInsert({
                //     PageName: 'Player Search [Filter]',
                //     Message: 'Clicked Filter: Position',
                //     PageType: PageType.Search,
                // }));
            }
        }
    }

    public static clearPositionFilter() {
        return dispatch => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                positionFilter: null,
            })));
        }
    }

    public static clearPositionFilterAsync() {
        return dispatch => {
            dispatch(Actions.clearPositionFilter());
            dispatch(GridActions.resetPage());
            dispatch(GridActions.applyFilters());

            // dispatch(Actions.sendUserActivity(`Clicked Clear`, 'Player Search [Filter By Position]'));
        }
    }

    public static applyPositionFilter(positionId: SearchPlayerPositionEnum, positionName: string) {
        return (dispatch, getState: () => AppState) => {
            const position = positionName === positionFullNames.W || positionName === positionFullNames.F ?
                positionName :
                getPositionCodeByName(positionName)

            dispatch(stateController.setState({ positionFilter: positionId }));

            dispatch(GridActions.applyFilters());

            dispatch(Actions.togglePositionFilterModal(false));
            dispatch(Actions.sendUserActivity(`Position: ${position}`, 'Player Search [Filter]'));
        }
    }

    public static onSelectPlayerPosition(positionId: SearchPlayerPositionEnum, positionName: string) {
        return (dispatch, getState: () => AppState) => {
            const position = positionName === positionFullNames.W || positionName === positionFullNames.F ?
                positionName :
                getPositionCodeByName(positionName)
            // dispatch(stateController.setState({ positionFilter: positionId }));
            dispatch(Actions.sendUserActivity(`Position: ${position}`, 'Search [Quick Filter By Position]'));
        }
    }


    public static setPlayerAdType(type: PlayerAdTypeEnumModel) {
        return (dispatch, getState: () => AppState) => {
            const { sortToBuy, sortToLoan } = Selectors.getRoot(getState());

            if (type === PlayerAdTypeEnumModel.Buy) {
                dispatch(stateController.setState({
                    sortToBuy: sortToBuy ? null : type,
                }));
            } else if (type === PlayerAdTypeEnumModel.Loan) {
                dispatch(stateController.setState({
                    sortToLoan: sortToLoan ? null : type,
                }));
            }

            dispatch(GridActions.applyFilters());
        };
    }

    public static clearPlayerAdTypeFilters() {
        return dispatch => {
            dispatch(stateController.setState((prevState) => ({
                ...prevState,
                sortToBuy: null,
                sortToLoan: null,
            })));
            dispatch(GridActions.applyFilters());
        }
    }

}

class Selectors {
    public static getRoot = (state: AppState) => state.clubPitchOpportunities.filterOpportunities;
    public static getPositions = (state: AppState) => Selectors.getRoot(state).positions;
    public static getPositionFilter = (state: AppState) => Selectors.getRoot(state).positionFilter;
    public static getSortToBuyFilter = (state: AppState) => Selectors.getRoot(state).sortToBuy;
    public static getSortToLoanFilter = (state: AppState) => Selectors.getRoot(state).sortToLoan;
    public static getPositionFilterName = (state: AppState) => {
        const substate = Selectors.getRoot(state);
        const position = substate.positionFilter;
        if (!position) {
            return '';
        }
        const positionName = substate.positions.find(p => p.id === position).name;

        return positionName ? positionName : '';
    }
    public static getTransferTypeFilterName = (state: AppState) => {
        const { transferFee, annualGrossSalary, loanFee, } = Selectors.getTransferTypeBudgetModalState(state);
        const isActiveLoanFeeFilter = Selectors.isActiveLoanFeeFilter(state);
        const isActiveTransferFeeFilter = Selectors.isActiveTransferFeeFilter(state);
        const isActiveAnnualGrossSalaryFilter = Selectors.isActiveAnnualGrossSalaryFilter(state);

        const displayWithUnits = (value: number) => {
            const numberWithoutCommas = parseInt(value.toString().replace(/,/g, ''))

            return MarketValueSelector.displayMarketValue(numberWithoutCommas) + MarketValueSelector.valueUnts(numberWithoutCommas);
        };

        if ((isActiveTransferFeeFilter || isActiveAnnualGrossSalaryFilter) && isActiveLoanFeeFilter) {
            return 'Buy & Loan';
        }

        if (isActiveTransferFeeFilter && isActiveAnnualGrossSalaryFilter) {
            return `Buy`;
        }

        if (isActiveTransferFeeFilter) {
            return `Buy - Max: ${displayWithUnits(transferFee.max)}`;
        }

        if (isActiveAnnualGrossSalaryFilter) {
            return `Buy - Max: ${displayWithUnits(annualGrossSalary.max)}`;
        }

        if (isActiveLoanFeeFilter) {
            return `Loan - Max: ${displayWithUnits(loanFee.max)}`;
        }

        return 'Transfer type & budget';
    }
    public static getPositionFilterModal = (state: AppState) => Selectors.getRoot(state).positionFilterModal;
    public static isActivePositionFilter = (state: AppState) => Selectors.getPositionFilter(state) !== null;
    public static isActiveSortToBuyFilter = (state: AppState) => Selectors.getSortToBuyFilter(state) !== null;
    public static isActiveSortToLoanFilter = (state: AppState) => Selectors.getSortToLoanFilter(state) !== null;
    public static getTransferTypeBudgetModalState = (state: AppState) => Selectors.getRoot(state).transferTypeBudgetModal;
    public static getPlayerFilterModalState = (state: AppState) => Selectors.getRoot(state).playerFilterModal;
    public static isActiveTransferFeeFilter = (state: AppState) => Selectors.getTransferTypeBudgetModalState(state).transferFee.min !== defaultState.transferTypeBudgetModal.transferFee.min ||
        Selectors.getTransferTypeBudgetModalState(state).transferFee.max !== defaultState.transferTypeBudgetModal.transferFee.max;
    public static isActiveAnnualGrossSalaryFilter = (state: AppState) => Selectors.getTransferTypeBudgetModalState(state).annualGrossSalary.min !== defaultState.transferTypeBudgetModal.annualGrossSalary.min ||
        Selectors.getTransferTypeBudgetModalState(state).annualGrossSalary.max !== defaultState.transferTypeBudgetModal.annualGrossSalary.max;
    public static isActiveLoanFeeFilter = (state: AppState) => Selectors.getTransferTypeBudgetModalState(state).loanFee.min !== defaultState.transferTypeBudgetModal.loanFee.min ||
        Selectors.getTransferTypeBudgetModalState(state).loanFee.max !== defaultState.transferTypeBudgetModal.loanFee.max;
    public static isActiveTransferTypeBudgetFilter = (state: AppState) =>
        Selectors.isActiveTransferFeeFilter(state) ||
        Selectors.isActiveAnnualGrossSalaryFilter(state) ||
        Selectors.isActiveLoanFeeFilter(state);
    public static isActivePlayerFilter = (state: AppState) => Selectors.getPlayerFilterModalState(state).selectedPlayer !== null;
    public static isActiveAnyFilter = (state: AppState) =>
        Selectors.isActivePositionFilter(state) ||
        Selectors.isActiveSortToBuyFilter(state) ||
        Selectors.isActiveSortToLoanFilter(state) ||
        Selectors.isActiveTransferFeeFilter(state) ||
        Selectors.isActiveAnnualGrossSalaryFilter(state) ||
        Selectors.isActiveLoanFeeFilter(state) ||
        Selectors.isActivePlayerFilter(state);
}

const reducer = stateController.getReducer();

export {
    reducer as Reducer,
    FilterOpportunitiesState as State,
    Actions,
    Selectors,
    stateController as Controller,
};
