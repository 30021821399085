import axios from 'axios-config';
import {
    InsightsRecentPitchesResponse,
    InsightsActivityResponse,
    InsightsHighlightsResponse,
    InsightsCountersType,
    InsightsSentPitchesResponse,
    SentPitchesEnum,
} from 'api/transfers-out/models/transfers-out-insights.model';
import { OpportunitiesResponse } from 'api/transfers-out/models/transfers-out';

export default class TransfersOutInsightsService {
    private static axiosConfig = {
        headers: { 'Content-Type': 'application/json' },
    };

    public static async getRecentPitches(): Promise<InsightsRecentPitchesResponse> {
        const { data } = await axios.get(`api/v2/insights/recent-pitches`, { ...this.axiosConfig });
        return data;
    }

    public static async getActivity(): Promise<InsightsActivityResponse> {
        const { data } = await axios.get(`api/v2/insights/activity`, { ...this.axiosConfig });
        return data;
    }

    public static async getPlayerActivity(playerId: number): Promise<InsightsActivityResponse> {
        const { data } = await axios.get(`api/v2/insights/activity/${playerId}`, {
            ...this.axiosConfig,
        });

        return data;
    }

    public static async getHighlights(): Promise<InsightsHighlightsResponse> {
        const { data } = await axios.get(`api/v2/insights/highlights/30`, { ...this.axiosConfig });
        return data;
    }

    public static async getInsightsCounters(playerId: number): Promise<InsightsCountersType> {
        const { data } = await axios.get(`api/v2/insights/counters/${playerId}`, {
            ...this.axiosConfig,
        });
        return data;
    }

    public static async getPitchOpportunitiesPlayer(
        playerId: number,
    ): Promise<OpportunitiesResponse> {
        const { data } = await axios.get(`api/v2/insights/opportunities/${playerId}`, {
            ...this.axiosConfig,
        });
        return data;
    }

    public static async getInsightsSentPitches(
        playerId: number,
        screenType: SentPitchesEnum,
    ): Promise<InsightsSentPitchesResponse> {
        return await axios.post(
            `api/v2/insights/pitches-sent`,
            {
                playerId,
                screenType,
            },
            { ...this.axiosConfig },
        );
    }
}
