import * as redux from 'redux';
import * as GridController from './grid.controller';
import * as FilterController from './filter.controller'

export class ClubPitchOpportunitiesState {
    pitchOpportunities: GridController.State;
    filterOpportunities: FilterController.State;
}

export function combineReducers() {
    const PitchOpportunitiesReducers = {
        pitchOpportunities: GridController.Reducer,
        filterOpportunities: FilterController.Reducer,
    };

    return redux.combineReducers(PitchOpportunitiesReducers);
}